<script setup lang="ts">
import type { VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    clickableText: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Expandable Content Block', group: 'Kimpton', layouts: ['Kimpton'] },
  slots: [{ name: 'default', allowedElements: [] }],
  description: 'Expandable content block',
  preview: 'SlicesKimptonExpandableContentBlock.jpg',
  fields: {
    clickableText: {
      type: 'text',
      label: 'Clickable Text',
    },
    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      clickableText: {
        value: 'Click to expand',
      },
      copy: {
        value: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec justo ultricies aliquet. Nullam nec purus nec justo ultricies aliquet. Nullam nec purus nec justo ultricies aliquet. Nullam nec purus nec justo ultricies aliquet.</p>',
      },
    },
  }],
})

const copyOpen = ref(false)
</script>

<template>
  <div>
    <div class="container mx-auto ">
      <button class="text-kimpton-secondary font-bold flex items-center justify-between" @click="copyOpen = !copyOpen">
        <span>{{ fields.clickableText.value }}</span>
        <Icon
          name="heroicons:chevron-down-20-solid" class="w-6 h-6 duration-500" :class="{
            'transform rotate-180': copyOpen,
          }"
        />
      </button>
      <div v-show="copyOpen" class="mt-4">
        <VoixWysiwyg :field="fields.copy" class="leading-12 wysiwyg" />
        <slot />
      </div>
    </div>
  </div>
</template>
